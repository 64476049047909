@import '../mixins/i18n.scss';
@import '../scss-variables/fonts.scss';
@import '../scss-variables/shared.scss';

.custom-button {
  letter-spacing: 0;
  position: relative;
  height: $button-height;
  margin: auto;
  padding: 0;
  text-transform: initial;
  @include set-font-size(16px);
  @include set-line-height(22px);
  --box-shadow: none;
  --border-radius: #{$button-border-radius};

  .custom-button-icon {
    width: 24px;
    height: 24px;

    &.end {
      padding: 0;
      position: absolute;
      @include position(null, 0px, null, null);
    }
  }

  &[color='red'],
  &[color='green'] {
    @include set-font-weight($font-weight-medium);
  }

  &[fill='outline'] {
    --border-width: 2px;
  }

  ion-button[color='grey'] {
    --ion-color-shade: transparent;
  }
}
