// We use these CSS environmet variables to be able to get the safe area inset using javascript
// https://benfrain.com/how-to-get-the-value-of-phone-notches-environment-variables-env-in-javascript-from-css/
// Note: DO NOT modify these names without updating safe-area-inset.service.ts (!)
:root {
  --js-safe-area-inset-top: constant(safe-area-inset-top);
  --js-safe-area-inset-top: env(safe-area-inset-top);

  --js-safe-area-inset-bottom: constant(safe-area-inset-bottom);
  --js-safe-area-inset-bottom: env(safe-area-inset-bottom);

  --js-safe-area-inset-left: constant(safe-area-inset-left);
  --js-safe-area-inset-left: env(safe-area-inset-left);

  --js-safe-area-inset-right: constant(safe-area-inset-right);
  --js-safe-area-inset-right: env(safe-area-inset-right);
}
