// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #009cda;
  --ion-color-primary-rgb: 0, 156, 218;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0089c0;
  --ion-color-primary-tint: #1aa6de;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #eeeeee;
  --ion-color-light-tint: #f5f6f9;

  // ---------------
  // Custom colors
  // ---------------

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #f5eeee;
  --ion-color-white-tint: #ffffff;

  --ion-color-dark-white: #f7f1f1;
  --ion-color-dark-white-rgb: 247, 241, 241;
  --ion-color-dark-white-contrast: #000000;
  --ion-color-dark-white-contrast-rgb: 0, 0, 0;
  --ion-color-dark-white-shade: #d9d4d4;
  --ion-color-dark-white-tint: #f8f2f2;

  --ion-color-red: #e66363;
  --ion-color-red-rgb: 230, 99, 99;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 0, 0, 0;
  --ion-color-red-shade: #ca5757;
  --ion-color-red-tint: #e97373;

  --ion-color-green: #00daab;
  --ion-color-green-rgb: 0, 218, 171;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #00c096;
  --ion-color-green-tint: #1adeb3;

  --ion-color-blue: #00addb;
  --ion-color-blue-rgb: 0, 173, 219;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #0098c1;
  --ion-color-blue-tint: #1ab5df;

  --ion-color-orange: #f0ad4e;
  --ion-color-orange-rgb: 240, 173, 78;
  --ion-color-orange-contrast: #484848;
  --ion-color-orange-contrast-rgb: 0, 0, 0;
  --ion-color-orange-shade: #d39845;
  --ion-color-orange-tint: #f2b560;

  --ion-color-yellow: #cccc00;
  --ion-color-yellow-rgb: 204, 204, 0;
  --ion-color-yellow-contrast: #484848;
  --ion-color-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-shade: #b4b400;
  --ion-color-yellow-tint: #d1d11a;

  --ion-color-grey: #f4f4f4;
  --ion-color-grey-rgb: 244, 244, 244;
  --ion-color-grey-contrast: #484848;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #d7d7d7;
  --ion-color-grey-tint: #f5f5f5;

  --ion-color-header-light-color: #009cda;
  --ion-color-header-light-color-rgb: 0, 156, 218;
  --ion-color-header-light-color-contrast: #ffffff;
  --ion-color-header-light-color-contrast-rgb: 255, 255, 255;
  --ion-color-header-light-color-shade: #0089c0;
  --ion-color-header-light-color-tint: #1aa6de;

  --ion-color-header-dark-color: #008ac1;
  --ion-color-header-dark-color-rgb: 0, 138, 193;
  --ion-color-header-dark-color-contrast: #ffffff;
  --ion-color-header-dark-color-contrast-rgb: 255, 255, 255;
  --ion-color-header-dark-color-shade: #0079aa;
  --ion-color-header-dark-color-tint: #1a96c7;

  --ion-color-border-light-color: #ebebeb;
  --ion-color-border-light-color-rgb: 235, 235, 235;
  --ion-color-border-light-color-contrast: #000000;
  --ion-color-border-light-color-contrast-rgb: 0, 0, 0;
  --ion-color-border-light-color-shade: #cfcfcf;
  --ion-color-border-light-color-tint: #ededed;

  --ion-color-border-medium-color: #f1f1f1;
  --ion-color-border-medium-color-rgb: 241, 241, 241;
  --ion-color-border-medium-color-contrast: #000000;
  --ion-color-border-medium-color-contrast-rgb: 0, 0, 0;
  --ion-color-border-medium-color-shade: #d4d4d4;
  --ion-color-border-medium-color-tint: #f2f2f2;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-dark-white {
  --ion-color-base: var(--ion-color-dark-white);
  --ion-color-base-rgb: var(--ion-color-dark-white-rgb);
  --ion-color-contrast: var(--ion-color-dark-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-white-shade);
  --ion-color-tint: var(--ion-color-dark-white-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

.ion-color-header-light-color {
  --ion-color-base: var(--ion-color-header-light-color);
  --ion-color-base-rgb: var(--ion-color-header-light-color-rgb);
  --ion-color-contrast: var(--ion-color-header-light-color-contrast);
  --ion-color-contrast-rgb: var(--ion-color-header-light-color-contrast-rgb);
  --ion-color-shade: var(--ion-color-header-light-color-shade);
  --ion-color-tint: var(--ion-color-header-light-color-tint);
}

.ion-color-header-dark-color {
  --ion-color-base: var(--ion-color-header-dark-color);
  --ion-color-base-rgb: var(--ion-color-header-dark-color-rgb);
  --ion-color-contrast: var(--ion-color-header-dark-color-contrast);
  --ion-color-contrast-rgb: var(--ion-color-header-dark-color-contrast-rgb);
  --ion-color-shade: var(--ion-color-header-dark-color-shade);
  --ion-color-tint: var(--ion-color-header-dark-color-tint);
}

.ion-color-border-light-color {
  --ion-color-base: var(--ion-color-border-light-color);
  --ion-color-base-rgb: var(--ion-color-border-light-color-rgb);
  --ion-color-contrast: var(--ion-color-border-light-color-contrast);
  --ion-color-contrast-rgb: var(--ion-color-border-light-color-contrast-rgb);
  --ion-color-shade: var(--ion-color-border-light-color-shade);
  --ion-color-tint: var(--ion-color-border-light-color-tint);
}

.ion-color-border-medium-color {
  --ion-color-base: var(--ion-color-border-medium-color);
  --ion-color-base-rgb: var(--ion-color-border-medium-color-rgb);
  --ion-color-contrast: var(--ion-color-border-medium-color-contrast);
  --ion-color-contrast-rgb: var(--ion-color-border-medium-color-contrast-rgb);
  --ion-color-shade: var(--ion-color-border-medium-color-shade);
  --ion-color-tint: var(--ion-color-border-medium-color-tint);
}
