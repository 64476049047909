$default-border-radius: 5px;

$button-height: 45px;
$button-small-height: 28px;
$button-border-radius: 10px;
$button-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);

$fab-size: 60px;
$fab-mini-size: 50px;
$fab-content-margin: 16px;

$menu-item-image-width: 150px;
$menu-item-image-height: 150px;

$caterer-image-width: 100%;
$caterer-image-height: 200px;

$vendor-image-width: 100%;
$vendor-image-height: 200px;

$star-small-font-size: 18px;
$star-small-line-height: 24px;
$star-small-width: 24px;
$star-small-height: 24px;

$tablet-min-width: 768px;
$desktop-min-width: 1200px;

$max-content-width: 1072px;
