.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  direction: ltr !important;

  ion-spinner * {
    width: 28px;
    height: 28px;
  }
}

// Fix for spinner not working
// properly on RTL languages
[dir='rtl'] ion-spinner svg {
  right: initial !important;
}
