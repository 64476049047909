$white: var(--ion-color-white);
$dark-white: var(--ion-color-dark-white);
$red: var(--ion-color-red);
$green: var(--ion-color-green);
$blue: var(--ion-color-blue);
$orange: var(--ion-color-orange);
$yellow: var(--ion-color-yellow);
$grey: var(--ion-color-grey);

$header-light-color: var(--ion-color-header-light-color);
$header-dark-color: var(--ion-color-header-dark-color);

$border-light-color: var(--ion-color-border-light-color);
$border-medium-color: var(--ion-color-border-medium-color);

$text-light-color: #aaaaaa;
$text-medium-color: #777777;
$text-dark-color: #484848;

$white-button-disabled-color: #eeeeee;

$star-full-color: $green;
$star-empty-color: $white-button-disabled-color;

$face-rating-color-low: #da7500;
$face-rating-color-medium: #7500da;
$face-rating-color-high: #65da00;

$header-gradient: linear-gradient(
  130deg,
  rgba(0, 218, 171, 1) 0%,
  rgba(0, 214, 175, 1) 28.44%,
  rgba(0, 202, 188, 1) 57.9%,
  rgba(0, 183, 208, 1) 87.73%,
  rgba(0, 173, 219, 1) 100%
);
