@import '../mixins/i18n.scss';
@import '../scss-variables/colors.scss';

// TODO: Seems like footers withing modals have only 8px of padding/margin
// (for example, check the OrderReviewModalPage) because of a default style
// coming from Ionic. Please check if we can updated it to be 16px or whatever
// value we use for the footer in full-screen pages.

ion-modal {
  --background: transparent;

  ion-header ion-toolbar {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  &.modal-card {
    .ion-page {
      // We need to set the background color to avoid some
      // weird lines of 1px being shown when swiping down
      // the modal on iOS where the contenct intersects with
      // the header and the footer
      background: #ffffff;
    }
  }

  &.alert-modal {
    --height: fit-content;

    &::part(backdrop) {
      opacity: 0.4;
    }

    &::part(content) {
      transform: none;
    }
  }

  &.modal-sheet {
    --ion-color-step-350: #{$white};

    &::part(handle) {
      top: 10px;
    }

    .ion-page {
      padding-top: 24px;

      ion-content {
        overflow: hidden;
        --color: #424242;
        --background: #{$white};
        @include border-radius(20px, 20px, 0, 0);
      }
    }
  }

  &.bottom-action-sheet {
    --width: 100%;
    --height: fit-content !important;
    --max-height: calc(100vh - 44px);
    --max-height: calc(var(--vh, 100vh) - 44px);
    --border-radius: 10px !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;

    align-items: flex-end;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 16px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 16px);

    @media (min-width: $tablet-min-width) {
      align-items: center;
      --width: fit-content;
      --min-width: 400px;
      --max-width: 650px;
    }

    @media (min-width: $tablet-min-width) and (min-height: 700px) {
      --max-height: 650px;
    }

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        background: white;
        border-radius: 10px;
        overflow: auto;

        max-height: calc(100vh - 44px);
        max-height: calc(var(--vh, 100vh) - 44px);

        @media (min-width: $tablet-min-width) and (min-height: 700px) {
          max-height: 650px;
        }

        .bottom-action-sheet-header,
        .bottom-action-sheet-body {
          padding: 16px;

          @media (min-width: $tablet-min-width) {
            padding: 24px;
          }
        }

        .bottom-action-sheet-header {
          box-shadow: #0000000d 0 1px 16px;
        }

        .bottom-action-sheet-title {
          color: #424242;
          text-align: center;
          padding: 0;
          @include margin(0, 0, 12px);
          @include set-font-size(22px);
          @include set-line-height(30px);
          @include set-font-weight($font-weight-bold);
        }

        .bottom-action-sheet-description {
          color: #424242;
          text-align: center;
          padding: 0;
          @include margin(0, 0, 0);
          @include set-font-size(16px);
          @include set-line-height(22px);
        }

        .bottom-action-sheet-highlighted {
          @include set-font-weight($font-weight-bold);
        }

        .bottom-action-sheet-button {
          @include margin(0, 0, 12px, 0);

          &.danger {
            --ion-color-contrast: #e66363;
            @include set-line-height(22px);
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.multi-platform {
    align-items: flex-end;
    --height: calc(100vh - 44px);
    --height: calc(var(--vh, 100vh) - 44px);
    --border-radius: 20px 20px 0 0;

    @media (min-width: $tablet-min-width) {
      align-items: center;
      --border-radius: 20px;
    }

    @media (min-width: $tablet-min-width) and (min-height: 700px) {
      --max-height: 650px;
    }

    &.bottom {
      align-items: flex-end;
    }

    --height: fit-content !important;
    --max-height: calc(100vh - 44px);
    --max-height: calc(var(--vh, 100vh) - 44px);
    --width: 100%;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        background: white;
        border-radius: 20px 20px 0 0;
        overflow: auto;
        max-height: calc(100vh - 24px);
        max-height: calc(var(--vh, 100vh) - 24px);
        padding: 24px 16px 24px;
        padding-bottom: calc(constant(safe-area-inset-bottom) + 24px);
        padding-bottom: calc(env(safe-area-inset-bottom) + 24px);

        @media (min-width: $tablet-min-width) and (min-height: 700px) {
          max-height: 650px;
        }
      }
    }

    @media (min-width: $tablet-min-width) {
      --width: fit-content;
      --min-width: 400px;
      --max-width: 650px;

      .ion-page {
        .inner-content {
          padding-bottom: 24px;
          padding-bottom: 24px;
        }
      }
    }

    @media (min-width: $tablet-min-width) and (min-height: 700px) {
      --max-height: 650px;
    }
  }
}

@include browser() {
  ion-modal {
    align-items: flex-end;
    --height: calc(100vh - 44px);
    --height: calc(var(--vh, 100vh) - 44px);
    --border-radius: 20px 20px 0 0;

    @media (min-width: $tablet-min-width) {
      align-items: center;
      --max-width: 600px;
      --border-radius: 20px;
    }

    @media (min-width: $tablet-min-width) and (min-height: 700px) {
      --max-height: 650px;
    }

    // Auto-height modals
    // https://github.com/ionic-team/ionic-framework/issues/16852#issuecomment-449433452
    &.auto-height {
      &.bottom {
        align-items: flex-end;
      }

      --height: fit-content !important;
      --max-height: calc(100vh - 44px);
      --max-height: calc(var(--vh, 100vh) - 44px);
      --width: 100%;

      @media (min-width: $tablet-min-width) {
        --width: fit-content;
        --min-width: 400px;
        --max-width: 650px;
      }

      @media (min-width: $tablet-min-width) and (min-height: 700px) {
        --max-height: 650px;
      }

      .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
          background: white;
          border-radius: 20px 20px 0 0;
          overflow: auto;
          padding: 24px 16px;
          max-height: calc(100vh - 44px);
          max-height: calc(var(--vh, 100vh) - 44px);

          @media (min-width: $tablet-min-width) {
            padding: 24px;
          }

          @media (min-width: $tablet-min-width) and (min-height: 700px) {
            max-height: 650px;
          }
        }
      }
    }
  }
}

// This rule allows modals on top of modals to also show the backdrop otherwise
// things like showing a modal sheet on top of the order update modal would look
// weird as only the first modal has a backdrop
ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}
