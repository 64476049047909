@import '../mixins/i18n.scss';
@import '../mixins/platform.scss';
@import '../scss-variables/fonts.scss';
@import '../scss-variables/colors.scss';
@import '../scss-variables/shared.scss';

[dir='ltr'] ion-app.md,
[dir='ltr'] ion-app.ios {
  &,
  & * {
    font-family: 'Open Sans', sans-serif;
    --ion-font-family: 'Open Sans', sans-serif;
  }
}

[dir='rtl'] ion-app.md,
[dir='rtl'] ion-app.ios {
  &,
  & * {
    font-family: 'Tajawal', sans-serif;
    --ion-font-family: 'Tajawal', sans-serif;
  }
}

// Prevents the background from being set
// to black when using a dark theme on ios
// https://bilbayt.atlassian.net/browse/CA-1587
input,
textarea {
  background-color: white;
}

ion-button,
ion-input,
ion-input:not(.legacy-input) {
  // Ionic v7 updated the min height which makes
  // all our buttons taller than what they should be
  // https://bilbayt.atlassian.net/browse/CA-2870
  min-height: unset !important;
}

// Prevent input and textarea fields
// to get a border when focusing them
// https://bilbayt.atlassian.net/browse/CA-1505
*:focus {
  outline: none;
}

::-moz-selection {
  background: $green;
}

::selection {
  background: $green;
}

span.highlighted {
  color: var(--ion-color-green);
  @include set-font-weight($font-weight-medium);
}

@keyframes fadeIn {
  0%,
  50%,
  100% {
    opacity: 0.5;
  }
  25%,
  75% {
    opacity: 1;
  }
}

@keyframes scaleInFivePercent {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.05);
  }
}

@keyframes scaleInTenPercent {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.1);
  }
}

@keyframes swiper-pagination-animation {
  from {
    opacity: 0;
    transform: translateY(16px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes loading-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

.spinning-icon {
  animation: loading-spin 0.8s infinite linear;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $text-light-color;
  @include set-font-size(14px);
  @include set-line-height(28px);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $text-light-color;
  @include set-font-size(14px);
  @include set-line-height(28px);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $text-light-color;
  @include set-font-size(14px);
  @include set-line-height(28px);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $text-light-color;
  @include set-font-size(14px);
  @include set-line-height(28px);
}

div.empty {
  height: calc(100% - 72px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 350px;

  ion-icon {
    color: $header-light-color;
    opacity: 0.6;
    width: 100px;
    height: 100px;
  }

  h4 {
    opacity: 0.6;
    margin-top: 24px;
    margin-bottom: 0;
    color: $text-dark-color;
    @include set-font-weight($font-weight-medium);
    @include set-font-size(20px);
    @include set-line-height(26px);
  }

  p {
    opacity: 0.6;
    margin: 16px;
    padding: 0;
    color: $text-light-color;
    @include set-font-size(16px);
    @include set-line-height(22px);
  }

  ion-button[color='primary'] {
    margin: 16px 0 0;
    width: calc(100% - 64px) !important;
  }
}

ion-content > ion-grid.page-container[fixed] {
  --ion-grid-padding: 0px;
  --ion-grid-width: #{$max-content-width};
}

app-browser-header ion-grid.header-container[fixed],
app-browser-footer ion-grid.footer-container[fixed] {
  --ion-grid-padding: 0px;
  --ion-grid-width: #{calc($max-content-width - 32px)};
}

// TODO: do we need this??
// Ideally the header and footer should use the entire width
// because we'll use a different header/footer in the browser
// @media (min-width: $max-content-width) {
//   ion-header > ion-toolbar[fixed],
//   ion-footer > ion-toolbar[fixed] {
//     --padding-end: calc((100% - #{$max-content-width - 32px}) / 2) !important;
//     --padding-start: calc((100% - #{$max-content-width - 32px}) / 2) !important;
//   }
// }

ion-toggle {
  flex-shrink: 0;
  @include margin(null, null, null, 16px);
}

@include browser() {
  ion-tab-bar {
    display: none;
  }

  [tappable] {
    cursor: pointer;
  }
}

swiper.horizontal-list {
  .swiper-slide {
    width: auto;
  }
}

@include browser() {
  ion-content ion-grid.page-container {
    min-height: 100%;
  }
}
