@import '../mixins/i18n.scss';
@import '../scss-variables/fonts.scss';
@import '../scss-variables/colors.scss';

.alert-title {
  @include set-font-weight($font-weight-medium);
  @include set-font-size(18px);
  @include set-line-height(24px);
  color: $text-dark-color !important;
}

.alert-message {
  @include set-font-weight($font-weight-regular);
  @include set-font-size(16px);
  @include set-line-height(22px);
  color: $text-medium-color !important;
}

.alert-button > .alert-button-inner {
  @include set-font-weight($font-weight-medium);
  @include set-font-size(16px);
  @include set-line-height(22px);
}

/* Fix for inputs inside of Ionic Alerts */
input.alert-input {
  color: $text-dark-color;
  @include set-font-weight($font-weight-regular);
  @include set-font-size(14px);
  @include set-line-height(28px);

  @include rtl() {
    direction: rtl;
  }

  @include ltr() {
    direction: ltr;
  }
}

ion-alert {
  z-index: 99999 !important;
}
