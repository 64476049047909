@import './base.scss';
@import '../scss-variables/fonts.scss';

// -----------
// Font size
// -----------

@mixin set-font-size($size, $increase: $ar-text-increase) {
  @include ltr() {
    font-size: $size;
  }

  @include rtl() {
    font-size: $size + $increase;
  }
}

// -------------
// Font weight
// -------------

@mixin set-font-weight($weight) {
  @include ltr() {
    @if ($weight == $font-weight-regular) {
      font-weight: 400;
    } @else if ($weight == $font-weight-medium) {
      font-weight: 600;
    } @else if ($weight == $font-weight-bold) {
      font-weight: 700;
    }
  }

  @include rtl() {
    @if ($weight == $font-weight-regular) {
      font-weight: 400;
    } @else if ($weight == $font-weight-medium) {
      font-weight: 500;
    } @else if ($weight == $font-weight-bold) {
      font-weight: 700;
    }
  }
}

// -------------
// Line height
// -------------

@mixin set-line-height($size, $increase: $ar-text-increase) {
  @include rtl() {
    line-height: $size + $increase;
  }

  @include ltr() {
    line-height: $size;
  }
}

// --------
// Height
// --------

@mixin set-height($size, $increase: $ar-text-increase) {
  @include rtl() {
    height: $size + $increase;
  }
  @include ltr() {
    height: $size;
  }
}

@mixin set-min-height($size, $increase: $ar-text-increase) {
  @include rtl() {
    min-height: $size + $increase;
  }
  @include ltr() {
    min-height: $size;
  }
}

@mixin set-max-height($size, $increase: $ar-text-increase) {
  @include rtl() {
    max-height: $size + $increase;
  }
  @include ltr() {
    max-height: $size;
  }
}

@mixin set-all-heights($size, $increase: $ar-text-increase) {
  @include set-height($size, $increase);
  @include set-min-height($size, $increase);
  @include set-max-height($size, $increase);
}

// ------------
// Text align
// ------------

@mixin text-align($direction, $decorator: null) {
  @if $direction == start {
    text-align: left;
    text-align: start $decorator;
  } @else if $direction == end {
    text-align: right;
    text-align: end $decorator;
  } @else {
    text-align: $direction $decorator;
  }
}
