ion-header {
  ion-back-button {
    &::part(text) {
      display: none;
    }
  }

  &.white-header {
    ion-toolbar:last-of-type {
      --background: #{$white};
      box-shadow: 0 3px 6px #00000008;
    }

    ion-toolbar,
    ion-title,
    ion-button,
    ion-back-button {
      --color: #424242;
    }

    ion-title {
      @include set-font-size(18px);
      @include set-font-weight($font-weight-medium);
    }

    ion-back-button {
      --icon-font-size: 32px;
    }
  }

  &.transparent-header {
    ion-toolbar:last-of-type {
      --background: rgba(255, 255, 255, 0.75);
    }

    ion-toolbar,
    ion-title,
    ion-button,
    ion-back-button {
      --color: #424242;
    }

    ion-title {
      @include set-font-size(18px);
      @include set-font-weight($font-weight-medium);
    }

    ion-back-button {
      --icon-font-size: 32px;
    }
  }

  ion-toolbar {
    --padding-start: 10px;
    --padding-end: 16px;

    ion-title {
      @include set-font-size(16px);
      @include set-line-height(22px);
      @include set-font-weight($font-weight-medium);
    }

    ion-back-button {
      --icon-font-size: 26px;

      &[icon$='b-times.svg'] {
        --icon-font-size: 20px;
      }
    }

    ion-button {
      margin: 0 !important;
      @include set-font-size(14px);
      @include set-line-height(22px);
      --padding-start: 0 !important;
      --padding-end: 0 !important;

      &.header-cart-button,
      &.header-menu-button {
        --overflow: visible;
        position: relative;

        ion-icon {
          width: 20px;
          height: 32px;
        }

        ion-badge {
          position: absolute;
          @include position(0, -10px, null, null);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 18px;
          width: 18px;
          background-color: $red;
          border: 1px solid $white;
          font-size: 10px;
          line-height: 18px;

          @include rtl() {
            padding-top: 6px;
          }
        }
      }
    }
  }
}
