form.custom-form {
  width: 100%;
  max-width: 400px;
  margin: auto;

  ion-list.custom-form-wrapper {
    margin: 0 0 8px 0;
  }

  ion-item.custom-form-item {
    --min-height: 56px;
    --inner-border-width: 0;
    --inner-padding-end: 0;
    --padding-start: 0;
    --background-focused: none;

    border: none !important;
    margin-bottom: 16px;
    border-bottom: 2px solid $border-light-color;

    ion-label.custom-form-item-label {
      --color: #{$text-light-color};

      margin: 0;
      @include set-font-size(14px);
      @include set-line-height(28px);
      @include set-font-weight($font-weight-regular);

      &.hidden {
        visibility: hidden;
      }
    }

    ion-input.custom-form-item-input {
      --color: #{$text-dark-color};
      --padding-top: 0;
      --padding-bottom: 0;
      --padding-end: 0;
      --border-color: none;
      --border-width: 0;

      @include set-font-size(16px);
      @include set-line-height(28px);
    }

    ion-textarea.custom-form-item-textarea {
      --color: #{$text-dark-color};
      --padding-top: 0;
      --padding-bottom: 0;
      --padding-end: 0;

      -webkit-appearance: none;
      width: 100%;
      border: none;
      margin-top: 2px;
      @include set-font-size(16px);
      @include set-line-height(24px);
    }

    ion-icon.custom-form-item-icon {
      width: 18px;
      height: 18px;
      align-self: flex-end;
      @include margin(0, 0, 6px, 0);

      &.error-icon {
        color: $red;
      }

      &.dropdown-icon {
        color: #aaaaaa;
      }
    }
  }

  p.custom-form-item-info {
    margin-top: -8px;
    color: $text-light-color;
    margin-bottom: 0;
    @include set-font-size(12px);
    @include set-line-height(18px);
  }

  ion-row.custom-form-inline-items {
    ion-col.custom-form-inline-item:first-child {
      @include padding(0, 8px, 0, 0);
    }

    ion-col.custom-form-inline-item:last-child {
      @include padding(0, 0, 0, 8px);
    }
  }
}

ion-input {
  --border-radius: 0;
}

// The following styles are used in ion-item elements
// that are not part of a form but should look in
// the same way as we show forms.

.custom-item {
  --min-height: 56px;
  --inner-border-width: 0;
  --inner-padding-end: 0;
  --padding-start: 0;
  --background-focused: none;

  margin-bottom: 16px;
  border-bottom: 2px solid $border-light-color;

  ion-label.custom-item-label {
    --color: #{$text-light-color};

    margin: 0;
    @include set-font-size(14px);
    @include set-line-height(28px);

    &.hidden {
      visibility: hidden;
    }
  }

  ion-input.custom-item-input {
    --color: #{$text-dark-color};
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-end: 0;

    @include set-font-size(16px);
    @include set-line-height(28px);
  }

  ion-textarea.custom-item-textarea {
    --color: #{$text-dark-color};
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-end: 0;

    -webkit-appearance: none;
    width: 100%;
    border: none;
    margin-top: 2px;
    @include set-font-size(16px);
    @include set-line-height(24px);
  }

  ion-icon.custom-item-icon {
    width: 18px;
    height: 18px;
    align-self: flex-end;
    @include margin(0, 0, 6px, 0);

    &.error-icon {
      color: $red;
    }

    &.dropdown-icon {
      color: #aaaaaa;
    }
  }
}
