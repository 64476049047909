@import '../mixins/i18n.scss';

* {
  @include rtl() {
    &[src*='chevron'],
    &[src*='view-more'],
    &[src*='angle-left'],
    &[src*='angle-right'],
    &[icon*='angle-left'],
    &[icon*='angle-right'],
    &[src*='back-icon'] {
      transform: rotateY(180deg);
    }
  }
}
