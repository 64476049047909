// NOTE: the following styles are a fix for wrong
// padding/height in iPhone X footer when the keyboard
// is open.
// If you need to change these styles please test your
// changes in an iPhoneX or any other similar iOS device
// that uses the safe-area-inset-bottom

.md,
.ios {
  ion-footer {
    ion-toolbar {
      padding: 8px 10px;
      box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.07);

      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;
      --min-height: 36px;
      --background: #{$white};

      &:first-of-type {
        --border-width: 0;
      }

      &:last-of-type {
        padding-bottom: calc(constant(safe-area-inset-bottom) + 8px);
        padding-bottom: calc(env(safe-area-inset-bottom) + 8px);
      }
    }

    &.animated-footer {
      position: absolute;
      bottom: 0;
    }
  }
}

.without-safe-area-inset-bottom .ios ion-footer ion-toolbar:last-child {
  min-height: 44px;
}

.without-safe-area-inset-bottom .md ion-footer ion-toolbar:last-child {
  min-height: 56px;
}

[dir='ltr'] .without-safe-area-inset-bottom .ios ion-footer ion-toolbar:last-child,
[dir='rtl'] .without-safe-area-inset-bottom .ios ion-footer ion-toolbar:last-child,
[dir='ltr'] .without-safe-area-inset-bottom .md ion-footer ion-toolbar:last-child,
[dir='rtl'] .without-safe-area-inset-bottom .md ion-footer ion-toolbar:last-child {
  padding-bottom: 8px;
}
